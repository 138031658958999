import React, { useEffect, useState } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import KeyResources from "../../components/common/KeyResources";
import TopFormWithCenter from "../../components/common/knowledge/Header";
import Cards from "../../components/common/knowledge/Cards";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";
import {GoToPopup} from "../homepage"

export const blogImg = require("../../assets/img/key-resources/bc_1_2.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_2.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_2.png");
export const tick_icon = require("../../assets/img/tick-icon.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Why is it essential to meet the demands of Gen Z employees who are the latest entrants in the workplace?",
    url:
      "https://blog.workativ.com/the-modern-employee-and-modern-it-help-desk-self-service/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "One of the best methods to reduce helpdesk costs and help employees get quick support.",
    url: "/conversational-ai-platform/call-deflection",
    link: "Read Whitepaper",
    alt: "whitepaper"
  },
  {
    image: videoImg,
    header: "How to create a smart chatbot for IT helpdesk with zero coding.",
    url: "https://youtu.be/uMqbQ3A4h4E",
    link: "Watch Now",
    alt: "videoImg"
  }
];
const menu = [
  {
    url: "ROI on self-service",
    href: "#section-1"
  },
  {
    url: "Productivity gains and savings through self-service",
    href: "#section-2"
  },
  {
    url: "Self-serviced tickets vs IT support-aided tickets",
    href: "#section-3"
  },
  {
    url: "Automating repetitive tasks means costs saved",
    href: "#section-5"
  }
];
export const cardData = [
  {
    href: "#section-1",
    header: "Productivity gains through self-service via the virtual assistant",
    content:
      "The key to achieving a better ROI on the helpdesk is to have a modern and innovative solution.",
    backgroundClass: "card_one"
  },
  {
    href: "#section-3",
    header: "Self-serviced tickets vs. IT support-aided tickets",
    content:
      "According to MetricNet, on average, self-serviced tickets cost much less than ones that require to be seen by an IT Support agent.",
    backgroundClass: "card_two"
  },
  {
    href: "#section-4",
    header: "Say goodbye to increasing support ticket costs!",
    content:
      "Your organization is only going to incur higher costs as a result of hiring more people.",
    backgroundClass: "card_three"
  }
];

export default function KnowledgePage() {
  const [isOpen, setOpen] = React.useState(false);

  const [isActive, setIsActive] = React.useState();

  return (
    <>
      <TitleAndMetaTags
        title="IT Helpdesk innovation using Workativ's Conversational AI Chatbot and Process Automation platform for IT Helpdesk automation."
        description="The key to achieving a better ROI on the helpdesk is to have a modern and innovative solution. Build an Innovative Digital HR and IT helpdesk with Workativ Assistant"
        keywords={["IT Helpdesk Chatbot", "HR Chatbot", "Service Desk Chatbot"]}
        ogTitle="IT Helpdesk innovation using Workativ's Conversational AI Chatbot and Process Automation platform for IT Helpdesk automation."
        ogDescription="The key to achieving a better ROI on the helpdesk is to have a modern and innovative solution. Build an Innovative Digital HR and IT helpdesk with Workativ Assistant"
      />
      <Container>
      <Layout backgroundColor={"--green-bg mb-0"} logoFor="ASSISTANT">
          <>
            <div className="inno_tech">
              <section
                className="wv-banner wv-landing-page-1 --green-bg wv-banner-h320"
                id="scrollToHome"
              >
                <TopFormWithCenter>
                  <TopFormWithCenter.Content>
                    Innovative technology & ROI
                  </TopFormWithCenter.Content>
                  <TopFormWithCenter.Header>
                    For achieving greater ROI, shift to a next-gen workplace
                    support solution
                  </TopFormWithCenter.Header>

                  <RequestForm isFooterForm={false} />
                </TopFormWithCenter>
                
              </section>
              <section className=" pb-5 wv-section-mv-p pb-200 content_para">
                <div className="container">
                  <div className="row">
                    <div className="col">
                      <p className="wv-md-heading wv-weight-normal wv-text-primary wv-limited-content mb-3 mx-auto">
                        Innovative technology can enhance service
                        competitiveness and speed up IT service delivery and as
                        well help reduce costs. In several ways, innovative
                        technology like AI is impacting employee service success
                        and helping organizations achieve both efficiency and
                        effectiveness in IT support function.
                      </p>
                      <hr className="wv-limited-content mx-auto wv-section-mv-m mt-5 mb-5" />
                    </div>
                  </div>
                  <div className="row wv-limited-content mx-auto mar_bot_1">
                    <h4 className="wv-heading-h4 ">
                      If implemented efficiently, the ROI on innovative tech
                      like AI in terms of value:
                    </h4>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <img loading = "lazy"
                        src={tick_icon}
                        rel="Tick icon"
                        height={32}
                        width={32}
                        className="mb-2 max-width-35"
                      />
                      <h4 className="wv-heading-h4 mb-2">
                        Replace repetitive routine work
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <img loading = "lazy"
                        src={tick_icon}
                        rel="Tick icon"
                        height={32}
                        width={32}
                        className="mb-2 max-width-35"
                      />
                      <h4 className="wv-heading-h4 mb-2">
                        Eliminate the need for IT agent involvement
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12 ">
                      <img loading = "lazy"
                        src={tick_icon}
                        rel="Tick icon"
                        height={32}
                        width={32}
                        className="mb-2 max-width-35"
                      />
                      <h4 className="wv-heading-h4 mb-2">
                        Use resources for high-value tasks
                      </h4>
                    </div>
                    <div className="col-md-6 col-sm-12 col-xs-12">
                      <img loading = "lazy"
                        src={tick_icon}
                        rel="Tick icon"
                        height={32}
                        width={32}
                        className="mb-2 max-width-35"
                      />
                      <h4 className="wv-heading-h4 mb-2">
                        Possible reduction in capital costs
                      </h4>
                    </div>
                  </div>
                </div>
              </section>
              <Cards cards={cardData} />
              <section className="wv-main-content wv-section-mv-p0">
                <div className="container">
                  <div className="row">
                    <div className="col-md-3 col-sm-12 col-xs-12 --sh-sm mb-4">
                      <div className="dropdown wv-dropdown">
                        <button
                          className={`link ${isOpen ? "active" : ""}`}
                          onClick={() => setOpen(!isOpen)}
                        >
                          Explore this articles
                        </button>
                        <div
                          id="myDropdown"
                          className={`accordion-item submenu dropdown-content ${
                            !isOpen ? "collapsed" : ""
                          }`}
                        >
                          <li>
                            {menu.map(data => (
                              <AnchorLink
                                offset={390}
                                className="url_manipulation"
                                href={data.href}
                              >
                                {data.url}
                              </AnchorLink>
                            ))}
                          </li>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 --hd-sm">
                      <aside className="wv-sidenav max-width-255" id="myHeader">
                        <p className="wv-sm-heading">Explore this article</p>
                        <ul className="mb-5">
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-1"
                              className={
                                isActive === "ROI on self-service"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive("ROI on self-service");
                              }}
                            >
                              {" "}
                              ROI on self-service
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-2"
                              className={
                                isActive ===
                                "Productivity gains and savings through self-service"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "Productivity gains and savings through self-service"
                                );
                              }}
                            >
                              {" "}
                              Productivity gains and savings through
                              self-service
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-3"
                              className={
                                isActive ===
                                "Self-serviced tickets vs IT support-aided tickets"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "Self-serviced tickets vs IT support-aided tickets"
                                );
                              }}
                            >
                              {" "}
                              Self-serviced tickets vs IT support-aided tickets
                            </AnchorLink>
                          </li>
                          <li>
                            <AnchorLink
                              offset={() => 100}
                              href="#section-5"
                              className={
                                isActive ===
                                "Automating repetitive tasks means costs saved"
                                  ? "url_manipulation active"
                                  : "url_manipulation"
                              }
                              onClick={() => {
                                setIsActive(
                                  "Automating repetitive tasks means costs saved"
                                );
                              }}
                            >
                              {" "}
                              Automating repetitive tasks means costs saved
                            </AnchorLink>
                          </li>
                        </ul>{" "}
                        <AnchorLink offset={200} href="#scrollToHome">
                          {" "}
                          Back to top ↑
                        </AnchorLink>
                      </aside>
                    </div>
                    <div className="col-md-9 col-sm-12 col-xs-12">
                      <div className="wv-content-area">
                        <section className="wv-content-section" id="section-1">
                          <div className="row">
                            <div className="col">
                              <h4 className="wv-heading-h4 mb-2">
                                ROI on self-service
                              </h4>
                              <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                According to SDI, research shows a significant
                                increase in the adoption of self-service across
                                organizations over the past few years. The study
                                shows that more than 80% of surveyed IT
                                professionals across 300 companies understand
                                that AI is a critical component of their digital
                                transformation. Nearly 50% have already seen
                                measurable ROI from implementing new
                                technologies to drive service levels.
                              </p>
                              <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                Businesses need to encourage user adoption and
                                maintain usage of the self-service as a gateway
                                for all internal help desk requests to
                                demonstrate the ROI for self-service. In turn,
                                this will increase user productivity and help
                                bring down support costs.
                              </p>
                            </div>
                          </div>
                        </section>
                        <section
                          className="wv-content-section mar_bot_40"
                          id="section-2"
                        >
                          <div className="row">
                            <div className="col">
                              <h4 className="wv-heading-h4 mb-2">
                                Productivity gains and savings through
                                self-service via the virtual assistant
                              </h4>
                              <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                In terms of productivity gains, implementing a
                                virtual assistant with automation helps in
                                achieving
                              </p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <img loading = "lazy"
                                src={tick_icon}
                                rel="Tick icon"
                                height={32}
                                width={32}
                                className="mb-2 max-width-35"
                              />
                              <h4 className="wv-heading-h4 mb-2">
                                Increased productivity of end-users
                              </h4>
                            </div>
                            <div className="col-12 col-md-6">
                              <img loading = "lazy"
                                src={tick_icon}
                                rel="Tick icon"
                                height={32}
                                width={32}
                                className="mb-2 max-width-35"
                              />
                              <h4 className="wv-heading-h4 mb-2">
                                Increased productivity of support agents
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-12 col-md-6">
                              <img loading = "lazy"
                                src={tick_icon}
                                rel="Tick icon"
                                height={32}
                                width={32}
                                className="mb-2 max-width-35"
                              />
                              <h4 className="wv-heading-h4 mb-2">
                                Improved MTTR
                              </h4>
                            </div>
                            <div className="col-12 col-md-6">
                              <img loading = "lazy"
                                src={tick_icon}
                                rel="Tick icon"
                                height={32}
                                width={32}
                                className="mb-2 max-width-35"
                              />
                              <h4 className="wv-heading-h4 mb-2">
                                Improved SLAs
                              </h4>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col col-md-6">
                              <img loading = "lazy"
                                src={tick_icon}
                                rel="Tick icon"
                                height={32}
                                width={32}
                                className="mb-2 max-width-35"
                              />
                              <h4 className="wv-heading-h4 mb-2">
                                No increase in the number of agents
                              </h4>
                            </div>
                          </div>
                        </section>
                        <section className="wv-inner-banner wv-landing-page-1 mt-40 -innovative">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="pad_le_riht">
                                  <h3 className="wv-banner-text-small mb-5">
                                    Shifting to newer technology can increase
                                    savings on the helpdesk. Support costs are
                                    generally high for several companies across
                                    regions, globally. Here’s what it costs to
                                    provide support:
                                  </h3>
                                  <div className="row">
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                      <h1 className="wv-banner-text-large">
                                        $69
                                      </h1>
                                      <h3 className="wv-banner-text-small">
                                        On desktop support
                                      </h3>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                      <h1 className="wv-banner-text-large">
                                        $104
                                      </h1>
                                      <h3 className="wv-banner-text-small">
                                        On IT support
                                      </h3>
                                    </div>
                                    <div className="col-md-4 col-sm-12 col-xs-12">
                                      <h1 className="wv-banner-text-large">
                                        $20-24
                                      </h1>
                                      <h3 className="wv-banner-text-small">
                                        On average on L1 tickets
                                      </h3>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>

                        <section className="width_container">
                          <div className="container">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="wv-content-area">
                                  <section className="wv-content-section">
                                    <div className="row">
                                      <div className="col">
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          The cost-saving benefit that you can
                                          have on implementing an intelligent
                                          virtual assistant: your employees can
                                          receive support at a fraction of the
                                          cost that companies spend on
                                          delivering support.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          The cost of L1 resolution by Workativ
                                          Assistant is in the range of $1 to $2
                                          on average, which would amount to huge
                                          savings on IT help desk costs if
                                          implemented.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                                          The key to achieving a better ROI on
                                          the helpdesk is to have a modern and
                                          innovative solution. With Workativ
                                          Assistant, you give your employees a
                                          creative and engaging self- service
                                          that helps them self-resolve IT L1
                                          issues.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                  <section
                                    className="wv-content-section"
                                    id="section-3"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="wv-heading-h4 mb-2">
                                          Self-serviced tickets vs. IT
                                          support-aided tickets
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content ">
                                          According to MetricNet, on average,
                                          self-serviced tickets cost much less
                                          than ones that require to be seen by
                                          an IT Support agent. So, employee
                                          hours and costs can go down
                                          considerably with tickets that are
                                          self-resolved. Employees'
                                          self-resolving tickets reduce the
                                          demands that are imposed on the
                                          internal support team and reduces
                                          cost-per-call.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                  <section
                                    className="wv-content-section"
                                    id="section-4"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="wv-heading-h4 mb-2">
                                          Say goodbye to increasing support
                                          ticket costs!
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          More tickets to resolve implies that
                                          your costs are going to increase, and
                                          the situation is not made any better
                                          by increasing the size of the support
                                          team. Your organization is only going
                                          to incur higher costs as a result of
                                          hiring more people.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-4">
                                          Workativ Assistant is a SaaS-based
                                          platform to build a Virtual Assistant
                                          that provides Automated Resolution
                                          capabilities. Through ‘Automated
                                          Resolution,’ employees can get common
                                          issues and request resolutions without
                                          the need to call the helpdesk.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="wv-inner-banner wv-landing-page-1 -innovative mt-20">
                          <div className="container-fluid">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="">
                                  <h2 className="wv-banner-text-medium">
                                    Depending on your current costs per ticket,
                                    you can save up to 6 times or more by using
                                    Workativ Assistant!
                                  </h2>
                                  <p className="wv-md-heading1 mb-1">
                                    By smartly resolving simple requests
                                    automatically and directing employees to a
                                    live agent only for complex issues, Workativ
                                    Assistant is highly beneficial.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        <section className="pb-210 width_container">
                          <div className="container">
                            <div className="row">
                              <div className="" />
                              <div className="">
                                <div className="wv-content-area">
                                  <section
                                    className="wv-content-section"
                                    id="section-5"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="wv-heading-h4 mb-2">
                                          Automating repetitive tasks means
                                          costs saved
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          Expenses come down when issues become
                                          resolved autonomously. Why is saving
                                          money on the costs of a ticket
                                          relevant? The cost per ticket depends
                                          on the level of support required. For
                                          example, self-serviced tickets cost
                                          less than $2 each, while L1 help desk
                                          resolved tickets cost $20 each. That’s
                                          9x the cost savings!
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          Besides saving up on costs, more
                                          employees using Automated Resolution
                                          to resolve issues implies that their
                                          productivity is going to go up because
                                          the mundane and repetitive tasks are
                                          automated.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                  <section
                                    className="wv-content-section width_container"
                                    id="section-6"
                                  >
                                    <div className="row">
                                      <div className="col">
                                        <h4 className="wv-heading-h4 mb-2">
                                          Workativ Assistant is a high-yielding
                                          equivalent of a full-time human
                                          employee
                                        </h4>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          By smartly resolving simple requests
                                          automatically and directing employees
                                          to a live agent only for complex
                                          issues, Workativ Assistant is highly
                                          beneficial. Depending on your current
                                          costs per ticket, you can save up to 6
                                          times or more by using Workativ
                                          Assistant!
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content mb-3">
                                          For a better ROI, you should not have
                                          to choose a workplace support solution
                                          that is expensive and eats into your
                                          budgets due to a high investment cost.
                                          Workativ Assistant powers your
                                          helpdesk support with modern
                                          technology (AI + automation) at a very
                                          reasonable price.
                                        </p>
                                        <p className="wv-md-heading1 wv-weight-normal wv-text-primary wv-limited-content">
                                          Reach out to us today if you are
                                          interested in transforming your
                                          workplace support and let us show you
                                          how you can reduce your helpdesk
                                          costs.
                                        </p>
                                      </div>
                                    </div>
                                  </section>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
          <KeyResources cards={resoucesData} />
          {/* <GoToPopup /> */}
          <RequestForm isFooterForm={true} />
        </Layout>
        {/* <OnScrollPopup /> */}
      </Container>
    </>
  );
}
